define("commander/pods/components/map-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "87UoEQKv",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"map-route-points\",[],[[\"@route\",\"@weight\"],[[32,1],8]],null],[2,\"\\n\\n\"],[2,\"    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@lat\",\"@lng\"],[14,\"green\",0.4,[32,1,[\"firstLocation\",\"lat\"]],[32,1,[\"firstLocation\",\"lng\"]]]],null],[2,\"\\n    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@stroke\",\"@lat\",\"@lng\"],[7,\"green\",1,false,[32,1,[\"firstLocation\",\"lat\"]],[32,1,[\"firstLocation\",\"lng\"]]]],null],[2,\"\\n\\n\"],[2,\"    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@lat\",\"@lng\"],[14,\"red\",0.4,[32,1,[\"lastLocation\",\"lat\"]],[32,1,[\"lastLocation\",\"lng\"]]]],null],[2,\"\\n    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@stroke\",\"@lat\",\"@lng\"],[7,\"red\",1,false,[32,1,[\"lastLocation\",\"lat\"]],[32,1,[\"lastLocation\",\"lng\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/map-route/template.hbs"
    }
  });
});